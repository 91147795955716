var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('profile-loading') : _vm._e(), _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h2', {
    staticClass: "info-title"
  }, [_vm._v(_vm._s(_vm.$t('main.information')))]), _c('div', {
    staticClass: "info-cont"
  }, [_c('div', {
    staticClass: "info-cont__info"
  }, [_vm._m(0), _c('div', {
    staticClass: "info-cont__made"
  }, [_vm._v(" You made "), _c('span', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.binsCount))]), _vm._v(" bids ")])]), _c('div', {
    staticClass: "info-cont__bids"
  }, [_c('div', {
    staticClass: "info-cont__bid"
  }, [_c('div', {
    staticClass: "info-cont__status active"
  }, [_vm._v("Active")]), _c('div', {
    staticClass: "info-cont__result"
  }, [_c('span', [_vm._v(_vm._s(_vm.activeBinsCount))]), _vm._v(" bid")])]), _c('div', {
    staticClass: "info-cont__bid"
  }, [_c('div', {
    staticClass: "info-cont__status completed"
  }, [_vm._v("Completed")]), _c('div', {
    staticClass: "info-cont__result"
  }, [_c('span', [_vm._v(_vm._s(_vm.completedBinsCount))]), _vm._v(" bid")])]), _c('div', {
    staticClass: "info-cont__bid"
  }, [_c('div', {
    staticClass: "info-cont__status active"
  }, [_vm._v("Win")]), _c('div', {
    staticClass: "info-cont__result"
  }, [_c('span', [_vm._v(_vm._s(_vm.winBinsCount))]), _vm._v(" bid")])]), _c('div', {
    staticClass: "info-cont__bid"
  }, [_c('div', {
    staticClass: "info-cont__status fault"
  }, [_vm._v("Fault")]), _c('div', {
    staticClass: "info-cont__result"
  }, [_c('span', [_vm._v(_vm._s(_vm.faultBinsCount))]), _vm._v(" bid")])]), _c('div', {
    staticClass: "info-cont__bid"
  }, [_c('div', {
    staticClass: "info-cont__status fault"
  }, [_vm._v("Out of rank")]), _c('div', {
    staticClass: "info-cont__result"
  }, [_c('span', [_vm._v(_vm._s(_vm.outRankBinsCount))]), _vm._v(" bid")])])])]), _c('p', {
    staticClass: "info-details"
  }, [_vm._v(_vm._s(_vm.$t("main.current_bids_information")))])]), _vm.nftLists && _vm.nftLists.length ? _c('div', {
    staticClass: "nft"
  }, [_c('h2', {
    staticClass: "nft-title"
  }, [_vm._v("My NFT")]), _c('div', {
    staticClass: "nft-cont some"
  }, [_vm._l(_vm.nftLists, function (nft, index) {
    return _c('div', {
      key: index,
      staticClass: "nft-item"
    }, [_c('img', {
      attrs: {
        "src": nft.image_url,
        "alt": "1 YEAR Auction"
      }
    }), _c('div', {
      staticClass: "nft-item__info"
    }, [_c('div', {
      staticClass: "nft-item__header"
    }, [_c('div', {
      staticClass: "nft-item__title"
    }, [_vm._v(_vm._s(nft.name))]), _c('div', {
      staticClass: "nft-item__season",
      class: nft.attributes[0].value.toLowerCase()
    }, [_vm._v(_vm._s(nft.attributes[0].value))])]), _c('div', {
      staticClass: "nft-item__row"
    }, [nft.address ? _c('div', {
      staticClass: "nft-item__name"
    }, [_vm._v(_vm._s(_vm.$t("main.winner")))]) : _vm._e(), nft.address ? _c('div', {
      staticClass: "nft-item__value"
    }, [_vm._v(_vm._s(nft.address.substr(0, 4)) + "..." + _vm._s(nft.address.substr(nft.address.length - 7)))]) : _vm._e()])])]);
  }), _c('ProfileSlider', {
    attrs: {
      "nftLists": _vm.nftLists
    }
  })], 2)]) : _vm._e(), _c('div', {
    staticClass: "history"
  }, [_vm.activeAuction ? _c('div', {
    staticClass: "history-header"
  }, [_c('h2', {
    staticClass: "history-header__title"
  }, [_vm._v(_vm._s(_vm.$t('main.bids_history')))]), _c('router-link', {
    staticClass: "history-header__btn",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: _vm.activeAuction
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('main.make_new_bid')))])], 1) : _vm._e(), _c('div', {
    staticClass: "history-cont"
  }, [_c('div', {
    staticClass: "history-cont__scroll"
  }, [_c('div', {
    staticClass: "history-cont__header"
  }, [_c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.date")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.auction")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.season")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.bid")))]), _c('div', {
    staticClass: "history-cont__col"
  }, [_vm._v(_vm._s(_vm.$t("table.status")))]), _c('div', {
    staticClass: "history-cont__col"
  })]), _c('div', {
    staticClass: "history-cont__items"
  }, _vm._l(_vm.userTransactions, function (transaction, index) {
    return _c('div', {
      key: index,
      staticClass: "history-cont__item"
    }, [_c('div', {
      staticClass: "history-cont__col"
    }, [_vm._v(_vm._s(_vm._f("moment")(transaction.value.ts, "from", "now")))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_vm._v(_vm._s(transaction.year) + " " + _vm._s(_vm.$t("table.year")))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.season")))]), _vm._v(_vm._s(_vm.seasonConverter(transaction.value.season)))]), _c('div', {
      staticClass: "history-cont__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.bid")))]), _vm._v(_vm._s(transaction.value.amount / 1000000) + " xtz")]), _c('div', {
      staticClass: "history-cont__col",
      class: transaction.statusWin && !transaction.active ? 'completed' : transaction.statusWin ? 'active' : transaction.active && !transaction.auctionFinished ? 'active' : 'fault'
    }, [_vm._v(_vm._s(transaction.statusWin && !transaction.active ? 'Completed' : transaction.statusWin && transaction.auctionFinished ? 'Win' : transaction.active && !transaction.auctionFinished ? 'Active' : 'Fault'))]), _c('div', {
      staticClass: "history-cont__col"
    }, [transaction.active && !transaction.statusWin && !transaction.auctionFinished ? _c('button', {
      staticClass: "history-cont__btn increase",
      on: {
        "click": function click($event) {
          return _vm.increaseBid(transaction.year);
        }
      }
    }, [_vm._v("Increase")]) : _vm._e(), transaction.active && !transaction.statusWin ? _c('button', {
      staticClass: "history-cont__btn withdraw",
      on: {
        "click": function click($event) {
          return _vm.openModal(transaction);
        }
      }
    }, [_vm._v("Withdraw")]) : _vm._e(), transaction.statusWin && transaction.active && transaction.auctionFinished ? _c('button', {
      staticClass: "history-cont__btn increase",
      on: {
        "click": function click($event) {
          return _vm.takeNft(transaction);
        }
      }
    }, [_vm._v("Take nft")]) : _vm._e()])]);
  }), 0)])]), _c('router-link', {
    staticClass: "history-btn__mob",
    attrs: {
      "to": {
        name: 'Auction',
        params: {
          years: _vm.activeAuction
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('main.make_new_bid')))])], 1)]), _vm.isModalOpen ? _c('WithdrawalModal', {
    attrs: {
      "data": _vm.userData,
      "currentAuctionContract": _vm.currentAuctionContract,
      "close": _vm.close
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-cont__overall"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/overall.svg"),
      "alt": "Overall info"
    }
  }), _c('p', [_vm._v("Overall info")])]);
}]

export { render, staticRenderFns }