var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', [_c('Splide', {
    attrs: {
      "options": {
        width: 370,
        arrows: false,
        type: 'fade',
        speed: 1000,
        pagination: 'slider'
      }
    }
  }, _vm._l(_vm.nftLists, function (nft, index) {
    return _c('SplideSlide', {
      key: index
    }, [_c('div', {
      staticClass: "nft-item"
    }, [_c('img', {
      attrs: {
        "src": nft.image_url,
        "alt": "1 YEAR Auction"
      }
    }), _c('div', {
      staticClass: "nft-item__info"
    }, [_c('div', {
      staticClass: "nft-item__header"
    }, [_c('div', {
      staticClass: "nft-item__title"
    }, [_vm._v(_vm._s(nft.name))]), _c('div', {
      staticClass: "nft-item__season autumn",
      class: nft.attributes[0].value.toLowerCase()
    }, [_vm._v(_vm._s(nft.attributes[0].value))])]), _c('div', {
      staticClass: "nft-item__row"
    }, [nft.address ? _c('div', {
      staticClass: "nft-item__name"
    }, [_vm._v(_vm._s(_vm.$t("main.winner")))]) : _vm._e(), nft.address ? _c('div', {
      staticClass: "nft-item__value"
    }, [_vm._v(_vm._s(nft.address.substr(0, 4)) + "..." + _vm._s(nft.address.substr(nft.address.length - 7)))]) : _vm._e()])])])]);
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }