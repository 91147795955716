<template>
  <div v-if="loading">
    <Splide :options="{ width: 370, arrows: false, type: 'fade', speed: 1000, pagination: 'slider' }">
      <SplideSlide v-for="(nft, index) in nftLists" :key="index">
        <div class="nft-item">
          <img :src="nft.image_url" alt="1 YEAR Auction">
          <div class="nft-item__info">
            <div class="nft-item__header">
              <div class="nft-item__title">{{nft.name}}</div>
              <div class="nft-item__season autumn" :class="nft.attributes[0].value.toLowerCase()">{{nft.attributes[0].value}}</div>
            </div>
<!--            <div class="nft-item__row">-->
<!--              <div class="nft-item__name">bought for</div>-->
<!--              <div class="nft-item__value">0.11 xtz</div>-->
<!--            </div>-->
            <div class="nft-item__row">
              <div class="nft-item__name" v-if="nft.address">{{ $t("main.winner") }}</div>
              <div class="nft-item__value" v-if="nft.address">{{nft.address.substr(0,4)}}...{{nft.address.substr(nft.address.length - 7)}}</div>
            </div>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
export default {
  name: 'ProfileSlider',
  components: {
    Splide,
    SplideSlide,
  },
  props: ['nftLists'],
  data() {
    return {
      loading: false
    }
  },
  watch: {
    nftLists(val) {
      if (val.length) {
        setTimeout(() => {
          this.loading = true
        }, 200)
      }
    }
  }
};
</script>
<style scoped lang="scss">
.splide {
  margin: 0 auto;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
}
.nft {
  &-item {
    border-radius: 10px;
    overflow: hidden;
    background: #FFFFFF;
    max-width: 370px;
    width: 100%;
    img {
      width: 100%;
      height: 305px;
      object-fit: contain;
      background: #7D888A;
      @media (max-width: 576px) {
        height: 290px;
      }
    }
    &__info {
      padding: 20px 18px;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      @media (max-width: 768px) {
        padding: 16px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__season {
      padding: 4px 16px;
      border-radius: 10px;
      &.autumn {
        color: #ED7D37;
        background: rgba(237, 125, 55, 0.1);
      }
      &.spring {
        color: #B6DA06;
        background: rgba(182, 218, 6, 0.1);
      }
      &.winter {
        color: #086DF9;
        background: rgba(8, 109, 249, 0.1);
      }
      &.summer {
        color: #22FF00;
        background: rgba(34, 255, 0, 0.1);
      }
    }
    &__title {
      font-weight: bold;
    }
    &__row {
      margin-top: 16px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
    &__name {
      flex-basis: 85px;
      margin-right: 12px;
    }
    &__value {
      font-weight: bold;
    }
  }
}
</style>
